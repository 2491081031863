import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import {
  Section,
  HighlightSection,
  DarkSection,
  HighlightWrapper,
  LightWrapper,
  CoreContent,
  TextBlock,
  S1Image,
  S2Image,
  Darkener,
  S2Wrapper,
  S3Wrapper,
  S3TextBlock,
  StyledIcon,
} from './styles'

import Lander from './Lander'

import Button from '../../core/Button'
import { TopZigZag, BottomZigZag } from './ZigZag'

import colors from '../../../config/colors'

import { faBolt, faComment, faWrench } from '@fortawesome/free-solid-svg-icons'

function Home(props) {
  const {
    s1image: {
      childImageSharp: { fluid: s1fluid },
    },
    s2image: {
      childImageSharp: { fluid: s2fluid },
    },
  } = useStaticQuery(graphql`
    query {
      s1image: file(relativePath: { eq: "home-il2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s2image: file(relativePath: { eq: "home-il3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <main>
      <Lander />

      <Section>
        <LightWrapper>
          <h2>
            Soluções flexíveis
            <br /> de engenharia
          </h2>
          <CoreContent>
            <TextBlock>
              A Lelis Engenharia se destaca na oferta de soluções tecnológicas
              de engenharia para os mais diversos problemas, se adequando as
              necessidades do cliente. A nossa missão é oferecer soluções
              seguras e confiáveis para os problemas de mais diversas escalas. O
              atual diretor executivo é o engenheiro Daniel Lelis, Ms. c.,
              especialista em engenharia estrutural.
            </TextBlock>
            <S1Image fluid={s1fluid} alt="ilustração" />
          </CoreContent>
        </LightWrapper>
      </Section>

      <HighlightSection>
        <TopZigZag />
        <HighlightWrapper>
          <div>
            <StyledIcon icon={faBolt} />
            <span>Soluções rápidas e flexíveis</span>
          </div>
          <div>
            <StyledIcon icon={faComment} />
            <span>Atendimento personalizado</span>
          </div>
          <div>
            <StyledIcon icon={faWrench} />
            <span>
              Ferramentas de ponta <br />
              para soluções de ponta
            </span>
          </div>
        </HighlightWrapper>
        <BottomZigZag />
      </HighlightSection>

      <Section>
        <S2Wrapper>
          <LightWrapper>
            <h2>Atuação</h2>
            <CoreContent>
              <TextBlock>
                Sempre amparados por profissionais bem capacitados, oferecemos
                soluções para diversos setores, desde perícias e soluções
                residenciais, até projetos industriais.
              </TextBlock>
            </CoreContent>
          </LightWrapper>
          <S2Image fluid={s2fluid}>
            <p>
              Se quiser dar uma olhada mais aprofundada nos nossos setores de
              atuação:
            </p>
            <Button
              border
              hoverBackground={colors.light.background}
              hoverColor={colors.light.text}
              as={Link}
              to="atuacao"
            >
              CLIQUE AQUI!
            </Button>
            <Darkener />
          </S2Image>
        </S2Wrapper>
      </Section>

      <DarkSection>
        <S3Wrapper>
          <h2>
            Aqui nosso cliente tem
            <br /> um lugar especial
          </h2>
          <S3TextBlock>
            Todos os nossos clientes tem um lugar especial no nosso coração! Por
            isso procuramos oferecer a melhor experiência possível para cada um
            deles.
          </S3TextBlock>

          <Button
            border
            hoverBackground={colors.light.background}
            hoverColor={colors.light.text}
            as={Link}
            to="clientes"
          >
            NOSSOS CLIENTES
          </Button>
        </S3Wrapper>
      </DarkSection>
    </main>
  )
}

export { Home }

export default Home
