import styled from '@emotion/styled'

import Img from 'gatsby-image/withIEPolyfill'
import BGImage from 'gatsby-background-image'
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'

import spacings from '../../../config/spacings'
import colors from '../../../config/colors'
import constants from '../../../config/constants'
import { sheets } from '../../../config/typography'

import { TextBlock as TB, Darkener } from '../../core/styles'

// Section

export const Section = styled.section`
  position: relative;
  background: ${colors.light.background};
`

export const DarkSection = styled(Section)`
  background: ${colors.dark.background};
`

export const HighlightSection = styled(Section)`
  background: ${colors.core.primary};
  z-index: 2;
`

// Content Wrappers
// Todo: Change max-width value and better centralize the text content
export const Wrapper = styled.div`
  max-width: ${constants.sizes.maxWidth}px;
  margin: 0 auto;

  position: relative;
`

export const ContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  align-self: flex-start;
  flex-grow: 1;

  padding: ${spacings.large}rem;

  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    align-self: flex-start;
  }

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    align-items: center;
  }
`

export const LightWrapper = styled(ContentWrapper)`
  background: ${colors.light.background};

  color: ${colors.light.text};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.light.headers};
  }
`

export const DarkWrapper = styled(ContentWrapper)`
  background: ${colors.dark.background};

  color: ${colors.dark.text};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.dark.headers};
  }
`

export const HighlightWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: ${spacings.large}rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* flex-grow: 1; */

    color: ${colors.dark.text};
    text-align: center;
    ${sheets.default.smallish};

    & > span {
      margin-top: ${spacings.default}rem;
    }
  }

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      margin: ${spacings.large}rem auto;
    }
  }
`

export const S2Wrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const S3Wrapper = styled(DarkWrapper)`
  align-items: center;
  padding-bottom: ${spacings.large * 2}rem;
`

export const CoreContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1 1 1;

  text-align: justify;

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

// Text Blocks

export const TextBlock = styled(TB)`
  max-width: 40rem;

  @media only screen and (min-width: ${constants.sizes.medium + 1}px) {
    margin-top: ${spacings.largest}rem;
    margin-right: ${spacings.largest * 2}rem;
  }
`

export const S3TextBlock = styled(TextBlock)`
  margin-right: auto !important;
  margin-left: auto !important;
  align-self: center;
`

// Sx Images

export const S1Image = styled(Img)`
  min-width: 18rem;
  width: 30rem;

  border-radius: 0.5rem;
  box-shadow: -0.0625rem 0.0625rem 0.25rem #0002;

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    display: none;
  }
`

export const S2Image = styled(BGImage)`
  min-width: 20rem;
  width: 25rem;
  min-height: 32rem;

  color: ${colors.dark.text};
  ${sheets.default.smallish};

  padding: ${spacings.large}rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  position: relative;

  & > * {
    padding: ${spacings.small}rem;
  }

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    width: 100%;
    height: auto;
    min-height: auto;
  }
`

// An utility for darkening background images

export { Darkener }

// Highlight Specific

export const StyledIcon = styled(FAIcon)`
  font-size: 8rem;
`
