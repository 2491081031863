import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'
import Home from '../components/containers/Home'

function IndexPage() {
  return (
    <DefaultMaster title="Home">
      <Home />
    </DefaultMaster>
  )
}

export default IndexPage
