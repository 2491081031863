import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import Img from 'gatsby-image/withIEPolyfill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'

import RawLogo from '../../../core/Logo'

import colors from '../../../../config/colors'
import constants from '../../../../config/constants'
import spacings from '../../../../config/spacings'
import { fontSizes, sheets } from '../../../../config/typography'

export const Wrapper = styled.section`
  height: 100vh;
  height: calc(105vh - ${constants.headerHeight});

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: ${colors.dark.text};

  position: relative;

  ${sheets.headers.largest};

  @media only screen and (max-width: 600px) {
    ${sheets.headers.large};
  }
`

export const Logo = styled(RawLogo)`
  height: ${fontSizes.huge * 2}rem;
  margin-bottom: ${spacings.large}rem;

  @media only screen and (max-width: 600px) {
    height: ${fontSizes.huge * 1.75}rem;
  }
`

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;

  height: 100%;
  width: 100%;
`

export const StyledImage = styled(Img)`
  height: 100%;
  width: 100%;

  filter: brightness(0.6);
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & *:first-child {
    margin-right: ${spacings.default}rem;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const DownArrow = props => {
  const DAAnimation = keyframes`
    0% {
      transform: translateY(-50%);
      opacity: 0%;
    }

    20% {
      opacity: 100%;
    }

    100% {
      transform: translateY(0%);
      opacity: 0%;
    }
  `

  const ArrowWrapper = styled.div`
    position: absolute;
    bottom: 6vh;

    left: 0;
    right: 0;

    margin: 0 auto;

    font-size: ${fontSizes.larger}rem;

    animation: ${DAAnimation} 1.5s ease forwards infinite;

    pointer-events: none;
  `
  return (
    <ArrowWrapper>
      <FontAwesomeIcon icon={faAngleDoubleDown} />
    </ArrowWrapper>
  )
}
