import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import Button from '../../../core/Button'

import strings from '../../../../config/strings'
import colors from '../../../../config/colors'

import {
  Wrapper,
  BackgroundImageWrapper,
  StyledImage,
  Logo,
  ButtonRow,
  DownArrow,
} from './styles'
import { css } from '@emotion/core'

export default function Lander() {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home-il.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const biFluid = data.backgroundImage.childImageSharp.fluid

  return (
    <Wrapper>
      {/* Content */}
      <>
        <Logo />
        {strings.ptBR.home.callingText}
        <ButtonRow>
          <Link to="/sobre">
            <Button
              backgroundColor={colors.core.secondary}
              textColor={colors.dark.text}
              hoverStyleBackground={css`
                filter: brightness(1.2);
              `}
              as={Link}
              to="sobre"
            >
              {strings.ptBR.home.about}
            </Button>
          </Link>
          <Link to="/contato">
            <Button
              border
              hoverBackground={colors.light.background}
              hoverColor={colors.light.text}
              as={Link}
              to="contato"
            >
              {strings.ptBR.home.contact}
            </Button>
          </Link>
        </ButtonRow>
      </>

      {/* Absolutes */}

      <BackgroundImageWrapper>
        <StyledImage fluid={biFluid} objectPosition="50% 27.5%" />
      </BackgroundImageWrapper>
      <DownArrow />
    </Wrapper>
  )
}
