import React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

function RawZigZag({ reverse = false, ...props }) {
  const DefaultD = 'M0,72,480,36,960,72l480,36,480-36V180H0Z'
  const ReversedD = 'M1920,144l-480,36L960,144,480,108,0,144V36H1920Z'

  let D = DefaultD

  if (reverse) {
    D = ReversedD
  }

  return (
    <Wrapper {...props}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 144"
        preserveAspectRatio="none"
      >
        <Path d={D} transform="translate(0 -36)" />
      </Svg>
    </Wrapper>
  )
}

RawZigZag.propTypes = {
  reverse: PropTypes.bool.isRequired,
}

const Path = styled.path`
  fill: #262c84;
`
const Svg = styled.svg`
  width: 100vw;
  max-width: 100%;
  max-height: 80px;
`

const Wrapper = styled.div`
  position: absolute;
  left: 0;

  z-index: -1;
`

const RawTopZigZag = props => <RawZigZag reverse={false} {...props} />
const RawBottomZigZag = props => <RawZigZag reverse={true} {...props} />

export const TopZigZag = styled(RawTopZigZag)`
  top: 0;
  transform: translateY(-55%);
`

export const BottomZigZag = styled(RawBottomZigZag)`
  bottom: 0;
  transform: translateY(55%);
`
